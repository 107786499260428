.config-background {
  display: block;
  position: absolute;
  top: 0;
  width: 100%;
  height: 300px;
  background-image: linear-gradient(to right, rgb(255, 61, 48), rgb(255, 43, 97));
  z-index: -1;
}

.config-body {
  height: auto;
  min-height: 65vh;
  margin: 0 auto;
  margin: 2rem auto 0;
  padding: 2rem 3rem;
  max-width: 768px;
  background-color: white;
  z-index: 2;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.config-splitter {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  height: 1px;
  background-color: #d3dce0;
  border-width: 0;
}

.config-logo-bottom img {
  width: 150px;
  height: 150px;
  margin: 0 auto 80px;
  display: block;
}