html,
body,
div {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  ;
  box-sizing: border-box;
}

body {
  overflow-y: scroll;
}

#root {
  padding: .1em;
}

.player {
  width: 100%;
  align-items: center;
  justify-content: center;
}

.uploader_area {
  min-height: 250px;
  border: 1px dashed rgb(207, 217, 224);
  border-radius: 4px;
}

.uploader_area * {
  box-sizing: border-box;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.aspectratio {
  aspect-ratio: 16/9;
}

.copycodearea {
  min-height: 200px;
}

.reset-field-button {
  margin-left: 10px;
}